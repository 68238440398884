.login-container {
  position: relative; /* Menambahkan ini untuk membuat posisi pseudo-element relatif terhadap kontainer */
  /* background-image: url('./image/logobg.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #174d3c;
}

.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Warna hitam dengan 50% transparansi */
  z-index: 1; /* Pastikan ini berada di atas latar belakang tapi di bawah konten lainnya */
}

/* Untuk memastikan konten berada di atas pseudo-element */
.login-container > * {
  position: relative;
  z-index: 2;
}

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150vh;
  }
  
  .card {
    width: 350px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background-color: transparent;
  }
  
  .card-title {
    font-family: inherit;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0c9a24;
  }
  
  .form-container input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border 0.3s ease-in-out;
    text-align: center;

  }
  
  .form-container button {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border 0.3s ease-in-out;
    background-color: #007bff;
    color: #fff;
  }
  
  /* Add responsive styles as needed */
  
  .center-image {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
  
  .container-bottom {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Sesuaikan sesuai kebutuhan desain Anda */
  }
  
  .logo-container div {
    /* Gaya untuk logo */
  }
  
  .form-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    /* background-color: #f9f9f9; */
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .input-container {
    margin-bottom: 10px;
    text-align: left;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    outline: none;
    transition: border 0.3s ease-in-out;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #0077cc;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #005599;
  }
  